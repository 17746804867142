import { Box, Stack } from "@mui/material";
import Input from "./Input";
import MetricLabel from "./MetricLabel";
import ClearIcon from "@mui/icons-material/Clear";

const InputLayout = (props) => {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        margin: "auto",
        borderRadius: 5,
        marginTop: 3,
        bgcolor: "background.default",
      }}
    >
      <Stack direction="row" spacing={5}>
        <MetricLabel
          handleLabelChange={props.handleLabelChange}
          setMetricLabel={props.setMetricLabel}
          metricLabel={props.metricLabel}
          label="Metric Label"
          size="small"
        />
        <Input
          label="Initial Value"
          onFocus={props.handleOldNumberFocus}
          onChange={props.handleChangeOld}
          inputRef={props.inputRefOld}
          value={props.oldNumber}
        />
        <Input
          readOnly={true}
          label="Final Value"
          onFocus={props.handleNewNumberFocus}
          onChange={props.handleChangeNew}
          inputRef={props.inputRefNew}
          value={props.newNumber}
        />
      </Stack>
    </Box>
  );
};

export default InputLayout;
