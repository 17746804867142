import { Box, Typography, Stack } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const OutputLive = ({
	oldNumber,
	newNumber,
	oldNumberFocus,
	newNumberFocus,
	percentChange,
	changePrev,
	oldNumberPrev,
	newNumberPrev,
	metricLabel,
	greaterThanZero,
	positiveChangeIsBad
}) => {
	const directionColor = (number) => {
		if (number < 0 && !positiveChangeIsBad) {
			return 'error.main'
		} else if (number > 0 && !positiveChangeIsBad) {
			return 'success.main'
		} else if (number < 0 && positiveChangeIsBad) {
			return 'success.main'
		} else if (number > 0 && positiveChangeIsBad) {
			return 'error.main'
		} else return 'primary.main'
	}

	return (
		<Stack spacing={3}>
			<Box
				sx={{
					border: 'solid',
					borderWidth: 5,
					borderRadius: 5,
					display: 'flex',
					justifyContent: 'space-evenly',
					flexDirection: 'column',
					height: 'auto',
					minHeight: '270px',
					width: 'auto',
					minWidth: '400px',
					paddingX: 3
				}}
			>
				<Box display="block">
					{metricLabel != 'None' ? (
						<Box
							sx={{
								display: 'flex'
							}}
						>
							<Typography variant="metricLabel">{metricLabel}</Typography>
						</Box>
					) : null}
					{/* start big display (output) */}
					<Box
						sx={{
							display: 'flex',
							marginBottom: 1,
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						{oldNumberFocus && (
							<Typography
								variant="bigOutput"
								sx={{ opacity: !oldNumber ? 0.25 : 1 }}
							>
								{!oldNumber ? 'No. 1' : oldNumber}
							</Typography>
						)}
						{newNumberFocus && (
							<Typography
								variant="bigOutput"
								sx={{ opacity: !newNumber ? 0.5 : 1 }}
							>
								{!newNumber ? 'No. 2' : newNumber}
							</Typography>
						)}
						{!oldNumberFocus && !newNumberFocus && percentChange && (
							<Typography
								color={directionColor(percentChange)}
								variant="bigOutput"
							>
								{greaterThanZero && (
									<>
										<ArrowUpwardIcon sx={{ fontSize: 50 }} />
										{percentChange + '%'}{' '}
									</>
								)}
								{!greaterThanZero && (
									<>
										<ArrowDownwardIcon sx={{ fontSize: 50 }} />
										{percentChange + '%'}
									</>
								)}
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignContent: 'flex-end',
							width: '100%',
							paddingX: 1,
							paddingBottom: 1
						}}
					>
						<Box display="flex">
							<Typography variant="boldMetrics">
								{changePrev > 0 ? '+' + changePrev : changePrev}
							</Typography>
						</Box>
						<Box display="flex">
							{newNumberPrev && oldNumberPrev && (
								<Typography variant="boldMetrics2">
									{newNumberPrev} vs. {oldNumberPrev}
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Stack>
	)
}

const Output = (props) => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center'
				}}
			>
				<OutputLive {...props} />
			</Box>
		</Box>
	)
}

export default Output
