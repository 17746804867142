import "./styles.css";
import * as React from "react";
import Layout from "./Layout";
import {
  Box,
  ThemeProvider,
  CssBaseline,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { theme } from "./theme";
import CalculateIcon from "@mui/icons-material/Calculate";


const App = () => {
  const [open, setOpen] = useState(false);
  const [firstClick, setFirstClick] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setFirstClick(true);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <Stack direction="column" spacing={5}>
            <Typography variant="h4">Percentage Change Calculator</Typography>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={handleClick}>
                <CalculateIcon sx={{ color: "info.main", fontSize: "20rem" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
              }}
            >
              <Typography>
                Click the calculator icon or press 'Enter' to begin
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              <RapidApi />
            </Box> */}
          </Stack>
        </Box>
        <Layout
          open={open}
          setOpen={setOpen}
          firstClick={firstClick}
          setFirstClick={setFirstClick}
        />
      </ThemeProvider>
    </div>
  );
};
export default App;
