import * as React from 'react'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import ClearIcon from '@mui/icons-material/Clear'
import Stack from '@mui/material/Stack'

const ResetButton = (props) => {
	return (
		<Button
			onClick={() => props.handleResetAll()}
			variant="standard"
			size="small"
			startIcon={<ClearIcon />}
		>
			Reset All
		</Button>
	)
}

export default ResetButton
