import {
	TextField,
	Box,
	Select,
	InputLabel,
	FormControl,
	MenuItem
} from '@mui/material'

const MetricLabel = (props) => {
	const handleChange = (e) => {
		props.handleLabelChange(e)
	}
	const metrics = ['None', 'Avg. CPC', 'Conversions', 'Cost', 'Clicks']

	return (
		<Box sx={{ width: '90%', alignSelf: 'center' }}>
			<FormControl fullWidth size="small">
				<InputLabel>Metric</InputLabel>
				<Select
					value={props.metricLabel}
					label="Metric"
					onChange={(e) => handleChange(e)}
				>
					{metrics.map((metric, i) => (
						<MenuItem key={i} value={metric}>
							{metric}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{/* <TextField
				readOnly={props.readOnly === true ? true : false}
				onFocus={props.onFocus}
				inputRef={props.inputRef}
				onChange={(e) => props.onChange(e)}
				label={props.label}
				variant="standard"
				size="small"
				value={props.value}
			/> */}
		</Box>
	)
}

export default MetricLabel
