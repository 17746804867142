import { createTheme } from "@mui/material/styles";

// mui theme settings
export const theme = createTheme({
  palette: {
    primary: {
      // dark brown
      main: "#261c15",
    },
    secondary: {
      // teal
      main: "#0047AB",
    },
    accent: {
      // beige
      main: "#e4e6c3",
    },
    info: {
      // light blue
      main: "#00b7c3",
    },
    success: {
      // light green
      main: "#50c878",
    },
    warning: {
      // orange
      main: "#f05d23",
    },
    error: {
      // orange
      main: "#f05d23",
    },
    background: {
      // off-white
      default: "#F7F7F2",
      paper: "#f7f7f2",
    },
    tonalOffset: 0.2,
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    htmlFontSize: 14,
    fontSize: 14,
    bigOutput: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "6rem",
      fontWeight: 900,
    },
    metricLabel: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "2.5rem",
      fontWeight: 900,
    },
    boldMetrics: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "2rem",
      fontWeight: 900,
    },
    boldMetrics2: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "2rem",
      fontWeight: 900,
    },
    h1: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "4rem",
    },
    h2: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "3rem",
    },
    h3: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "2rem",
    },
    h4: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "1.75rem",
    },
    h5: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "1.5rem",
    },
    h6: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: "1.2rem",
    },
  },
});
