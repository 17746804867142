import { Box, Stack } from '@mui/material'
import DirectionSwitch from './DirectionSwitch'
import ResetButton from './ResetButton'

const Controls = (props) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
			<Stack spacing={3} direction="row">
				<DirectionSwitch
					positiveChangeIsBad={props.positiveChangeIsBad}
					setPositiveChangeIsBad={props.setPositiveChangeIsBad}
					label="Switch red/green"
				/>
				<ResetButton handleResetAll={props.handleResetAll} />
			</Stack>
		</Box>
	)
}

export default Controls
