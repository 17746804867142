import { Switch, Typography, Box } from '@mui/material'

const DirectionSwitch = (props) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginY: 1
			}}
		>
			<Switch
				checked={props.positiveChangeIsBad}
				label={props.label}
				onChange={() =>
					props.setPositiveChangeIsBad(!props.positiveChangeIsBad)
				}
			/>
			<Typography>Reverse colors</Typography>
		</Box>
	)
}

export default DirectionSwitch
