import { Box, TextField } from '@mui/material'

const Input = (props) => {
	return (
		<Box sx={{ width: '90%', alignSelf: 'center' }}>
			<TextField
				type={props.type ? props.type : undefined}
				InputLabelProps={{
					shrink: true
				}}
				readOnly={props.readOnly === true ? true : false}
				onFocus={props.onFocus}
				inputRef={props.inputRef}
				onChange={props.onChange}
				label={props.label}
				variant="standard"
				size={props.size}
				value={props.value}
				disabled={props.disabled}
			/>
		</Box>
	)
}

export default Input
