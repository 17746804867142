import { useReducer, useRef, useState, useEffect } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import InputLayout from "./InputLayout";
import Output from "./Output";
import Controls from "./Controls";


const initialState = {
  oldNumber: "",
  newNumber: "",
  change: "",
  oldNumberPrev: "",
  newNumberPrev: "",
  changePrev: "",
  percentChange: "",
  percentChangePrev: "",
};

const actionReducer = (state, action) => {
  switch (action.type) {
    case "OLD":
      return {
        ...state,
        oldNumber: action.value,
      };
    case "NEW":
      return {
        ...state,
        newNumber: action.value,
      };
    case "CALCULATE":
      return {
        ...state,
        change: (state.newNumber - state.oldNumber).toFixed(2),
        percentChange: (
          ((state.newNumber - state.oldNumber) / state.oldNumber) *
          100
        ).toFixed(2),
        oldNumberPrev: state.oldNumber,
        newNumberPrev: state.newNumber,
        changePrev: (state.newNumber - state.oldNumber).toFixed(2),
        percentChangePrev: (
          ((state.newNumber - state.oldNumber) / state.oldNumber) *
          100
        ).toFixed(2),
      };
    case "UPDATE_PREV":
      return {
        ...state,
        oldNumberPrev: state.oldNumber,
        newNumberPrev: state.newNumber,
        percentChangePrev: state.percentChange,
        changePrev: state.change,
      };
    case "RESET_INPUT":
      return {
        ...state,
        oldNumber: "",
        newNumber: "",
      };
    case "CLEAR_OLD":
      return {
        ...state,
        oldNumber: "",
      };
    case "CLEAR_NEW":
      return {
        ...state,
        newNumber: "",
      };
    case "RESET_ALL":
      return {
        state: initialState,
      };
    default:
      return initialState;
  }
};

const Layout = ({ open, setOpen, firstClick, setFirstClick }) => {
  const [state, dispatch] = useReducer(actionReducer, initialState);
  const [newNumberFocus, setNewNumberFocus] = useState(false);
  const [oldNumberFocus, setOldNumberFocus] = useState(false);
  const [metricLabel, setMetricLabel] = useState("None");
  const [greaterThanZero, setGreaterThanZero] = useState(true);
  const [positiveChangeIsBad, setPositiveChangeIsBad] = useState(null);

  const handleChangeOld = (e) => {
    dispatch({ type: "OLD", value: e.target.value });
  };
  const handleChangeNew = (e) => {
    dispatch({ type: "NEW", value: e.target.value });
  };
  const handleCalculate = () => {
    if (state.oldNumber !== "" && state.newNumber !== "") {
      dispatch({ type: "CALCULATE" });
    }
  };

  const handleResetAll = () => {
    dispatch({ type: "RESET_All" });
    setPositiveChangeIsBad(false);
    setMetricLabel("None");
    inputRefOld.current.focus();
  };

  const handleOldNumberFocus = () => {
    if (newNumberFocus) {
      setNewNumberFocus(false);
      setOldNumberFocus(true);
      setPositiveChangeIsBad(false);
      dispatch({ type: "CLEAR_OLD" });
    } else {
      setOldNumberFocus(true);
      setPositiveChangeIsBad(false);
      dispatch({ type: "CLEAR_OLD" });
    }
  };
  const handleNewNumberFocus = () => {
    if (oldNumberFocus) {
      setOldNumberFocus(false);
      setNewNumberFocus(true);
      setPositiveChangeIsBad(false);
      dispatch({ type: "CLEAR_NEW" });
    } else {
      setNewNumberFocus(true);
      setPositiveChangeIsBad(false);
      dispatch({ type: "CLEAR_NEW" });
    }
  };
  const handleLabelChange = (e) => {
    setMetricLabel(e.target.value);
  };

  // logic for controlling Enter key progression through inputs
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && oldNumberFocus && open) {
        handleNewNumberFocus();
        setTimeout(() => {
          inputRefNew.current.focus();
        }, 0);
      } else if (e.key === "Enter" && newNumberFocus && open) {
        handleCalculate();
        inputRefNew.current.blur();
        setNewNumberFocus(false);
      } else if (e.key === "Enter" && open !== true) {
        setOpen(true);
        setOldNumberFocus(true);
        dispatch({ type: "RESET_INPUT" });
        setTimeout(() => {
          inputRefOld.current.focus();
        }, 0);
      } else if (e.key === "0" && e.metaKey && open === true) {
        setOpen(false);
        setNewNumberFocus(false);
        setOldNumberFocus(false);
        dispatch({ type: "RESET_INPUT" });
      } else if (
        e.key === "Enter" &&
        !newNumberFocus &&
        !oldNumberFocus &&
        open
      ) {
        dispatch({ type: "RESET_INPUT" });
        setOldNumberFocus(true);
        setTimeout(() => {
          inputRefOld.current.focus();
        }, 0);
      } else return;
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [oldNumberFocus, newNumberFocus, open, handleCalculate]);

  useEffect(() => {
    setTimeout(() => {
      if (firstClick == true) {
        inputRefOld.current.focus();
        handleOldNumberFocus();
      } else {
        return;
      }
    }, 100);
  }, [firstClick]);

  useEffect(() => {
    setTimeout(() => {
      if (state.percentChange < 0) {
        setGreaterThanZero(false);
      } else if (state.percentChange > 0) {
        setGreaterThanZero(true);
      } else return;
    }, 0);
    dispatch({ type: "UPDATE_PREV" });
  }, [state.oldNumberPrev, state.newNumberPrev, state.percentChange]);

  const inputRefOld = useRef();
  const inputRefNew = useRef();

  const handleClose = () => {
    setOpen(false);
    inputRefNew.current.blur();
    setNewNumberFocus(false);
    inputRefOld.current.blur();
    setOldNumberFocus(false);
    setPositiveChangeIsBad(false);
    setFirstClick(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "65vw",
          maxWidth: "65vw",
          height: "auto",
          overflowY: "scroll",
          padding: 5,
        },
        "& .MuiDialog-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiDialog-paper": {
            background: "background.default",
            borderRadius: 5,
            maxHeight: "90vh",
          },
          borderRadius: 30,
          maxWidth: "false",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignContent: "space-evenly",
          maxWidth: "inherit",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 3,
          }}
        >
          <Typography variant="h5">Percentage Change Calculator</Typography>
        </Box>
        <Output
          oldNumber={state.oldNumber}
          newNumber={state.newNumber}
          oldNumberFocus={oldNumberFocus}
          newNumberFocus={newNumberFocus}
          change={state.change}
          percentChange={state.percentChange}
          changePrev={state.changePrev}
          percentChangePrev={state.percentChangePrev}
          oldNumberPrev={state.oldNumberPrev}
          newNumberPrev={state.newNumberPrev}
          metricLabel={metricLabel}
          greaterThanZero={greaterThanZero}
          positiveChangeIsBad={positiveChangeIsBad}
        />
        <Controls
          positiveChangeIsBad={positiveChangeIsBad}
          setPositiveChangeIsBad={setPositiveChangeIsBad}
          label="Switch red/green"
          handleResetAll={handleResetAll}
        />
        <InputLayout
          oldNumber={state.oldNumber}
          newNumber={state.newNumber}
          oldNumberFocus={oldNumberFocus}
          newNumberFocus={newNumberFocus}
          change={state.change}
          percentChange={state.percentChange}
          changePrev={state.changePrev}
          percentChangePrev={state.percentChangePrev}
          oldNumberPrev={state.oldNumberPrev}
          newNumberPrev={state.newNumberPrev}
          handleNewNumberFocus={handleNewNumberFocus}
          handleOldNumberFocus={handleOldNumberFocus}
          inputRefNew={inputRefNew}
          inputRefOld={inputRefOld}
          handleChangeNew={handleChangeNew}
          handleChangeOld={handleChangeOld}
          handleLabelChange={handleLabelChange}
          setMetricLabel={setMetricLabel}
          metricLabel={metricLabel}
        />
      </Box>
    </Dialog>
  );
};

export default Layout;
